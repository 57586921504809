import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
	/** Pre */
	{
		path: '/',
		name: 'home',
		component: () => import(/* webpackChunkName: "default-paywall" */ '@/views/paywall/Paywall.vue')
	},
	{
		path: '/invite-friends',
		name: 'invite-friends',
		component: () => import(/* webpackChunkName: "invite-friends" */ '@/views/InviteFriends.vue')
	},

	{
		path: '/offer-40off-home',
		name: 'offer-40off-home',
		component: () => import(/* webpackChunkName: "offer-40-off" */ '@/views/pre/Offer40offHome.vue')
	},

	{
		path: '/offer-50off',
		name: 'offer-50off',
		component: () => import(/* webpackChunkName: "offer-50-off" */ '@/views/paywall/Offer50OffPaywall.vue')
	},

	{
		path: '/ny-2022-without-survey-home',
		name: 'ny-2022-without-survey-home',
		component: () =>
			import(/* webpackChunkName: "ny-2022-without-srv-home" */ '@/views/pre/NY2022WithoutSurveyHome.vue')
	},

	{
		path: '/lose-weight-timeline',
		name: 'lose-weight-timeline',
		component: () => import(/* webpackChunkName: "lose-weight-timeline" */ '@/views/pre/LoseWeightTimeline.vue')
	},

	{
		path: '/weight-loss-guaranteed',
		name: 'weight-loss-guaranteed',
		component: () => import(/* webpackChunkName: "weight-loss-guaranteed" */ '@/views/pre/WeightLossGuaranteed.vue')
	},

	{
		path: '/ny-23-pre-paywall-insight',
		name: 'ny-23-pre-paywall-insight',
		component: () => import(/* webpackChunkName: "ny-23-paywall" */ '@/views/pre/NY23PrePaywall.vue'),
		props: {
			offer: 'NewYearInsightsInApp2023',
			paywallName: 'ny-23-paywall-insight'
		}
	},

	{
		path: '/ny-23-pre-paywall',
		name: 'ny-23-pre-paywall',
		component: () => import(/* webpackChunkName: "ny-23-paywall" */ '@/views/pre/NY23PrePaywall.vue')
	},

	{
		path: '/feb-24-pre-paywall',
		name: 'feb-24-pre-paywall',
		component: () => import(/* webpackChunkName: "feb-24-paywall" */ '@/views/pre/Feb24PrePaywall.vue')
	},
	{
		path: '/mar-24-pre-paywall',
		name: 'mar-24-pre-paywall',
		component: () => import(/* webpackChunkName: "mar-24-paywall" */ '@/views/pre/Mar24PrePaywall.vue')
	},
	{
		path: '/spring-into-summer-pre-paywall',
		name: 'spring-into-summer-pre-paywall',
		component: () => import(/* webpackChunkName: "spring-into-summer" */ '@/views/pre/SpringIntoSummerPrePaywall.vue')
	},
	{
		path: '/ai-powered-pre-paywall',
		name: 'ai-powered-pre-paywall',
		component: () =>
			import(/* webpackChunkName: "ai-powered-pre-paywall" */ '@/views/pre/AiPoweredLoseWeightPrePaywall.vue')
	},
	{
		path: '/ny-24-pre-paywall',
		name: 'ny-24-pre-paywall',
		component: () => import(/* webpackChunkName: "ny-24-paywall" */ '@/views/pre/NY24PrePaywall.vue')
	},

	/** Paywalls */
	{
		path: '/paywall',
		name: 'paywall',
		component: () => import(/* webpackChunkName: "default-paywall" */ '@/views/paywall/Paywall.vue')
	},

	{
		path: '/paywall-after-cancel',
		name: 'paywall-after-cancel',
		component: () => import(/* webpackChunkName: "paywall-after-cancel" */ '@/views/paywall/PaywallAfterCancel.vue')
	},

	{
		path: '/personalised-paywall',
		name: 'personalised-paywall',
		component: () => import(/* webpackChunkName: "personalise-paywall" */ '@/views/paywall/PersonalisedPaywall.vue')
	},

	{
		path: '/ny-23-paywall',
		name: 'ny-23-paywall',
		component: () => import(/* webpackChunkName: "ny-23-paywall" */ '@/views/paywall/NY23Paywall.vue')
	},

	{
		path: '/ny-24-paywall',
		name: 'ny-24-paywall',
		component: () => import(/* webpackChunkName: "ny-24-paywall" */ '@/views/paywall/NY24Paywall.vue')
	},

	{
		path: '/ny-23-paywall-insight',
		name: 'ny-23-paywall-insight',
		component: () => import(/* webpackChunkName: "ny-23-paywall" */ '@/views/paywall/NY23Paywall.vue'),
		props: {
			offer: 'NewYearInsightsInApp2023'
		}
	},

	{
		path: '/feb-24-paywall',
		name: 'feb-24-paywall',
		component: () => import(/* webpackChunkName: "feb-24-paywall" */ '@/views/paywall/Feb24Paywall.vue')
	},

	{
		path: '/mar-24-paywall',
		name: 'mar-24-paywall',
		component: () => import(/* webpackChunkName: "mar-24-paywall" */ '@/views/paywall/Mar24Paywall.vue')
	},

	{
		path: '/spring-into-summer-paywall',
		name: 'spring-into-summer-paywall',
		component: () => import(/* webpackChunkName: "spring-into-summer" */ '@/views/paywall/SpringIntoSummerPaywall.vue')
	},

	{
		path: '/black-friday',
		name: 'black-friday',
		component: () => import(/* webpackChunkName: "black-friday" */ '@/views/pre/BlackFridayHome.vue')
	},

	{
		path: '/black-friday-paywall',
		name: 'black-friday-paywall',
		component: () => import(/* webpackChunkName: "black-friday" */ '@/views/paywall/BlackFridayPaywall.vue')
	},

	{
		path: '/offer-40off-paywall',
		name: 'offer-40off-paywall',
		component: () => import(/* webpackChunkName: "offer-40-off" */ '@/views/paywall/Offer40offPaywall.vue')
	},

	{
		path: '/upsale-trial',
		name: 'upsale-trial',
		component: () => import(/* webpackChunkName: "default-paywall" */ '@/views/paywall/UpsaleTrial.vue')
	},

	{
		path: '/offer-trial',
		name: 'offer-trial',
		component: () => import(/* webpackChunkName: "default-paywall" */ '@/views/paywall/OfferTrial.vue')
	},

	{
		path: '/ny22-paywall',
		name: 'ny22-paywall',
		component: () => import(/* webpackChunkName: "ny22-paywall" */ '@/views/paywall/NY22Paywall.vue')
	},

	{
		path: '/countdown-paywall',
		name: 'countdown-paywall',
		component: () => import(/* webpackChunkName: "countdown-paywall" */ '@/views/paywall/CountdownPaywall.vue')
	},

	{
		path: '/recipe-subscription',
		name: 'recipe-subscription',
		component: () => import(/* webpackChunkName: "recipe-subscription" */ '@/views/paywall/RecipeSubscription.vue')
	},

	/** Post */
	{
		path: '/workbooks',
		name: 'workbooks',
		component: () => import(/* webpackChunkName: "default-paywall" */ '@/views/post/Workbooks.vue')
	},

	{
		path: '/workout-workbook',
		name: 'workout-workbook',
		component: () => import(/* webpackChunkName: "default-paywall" */ '@/views/post/WorkoutWorkbook.vue')
	},

	{
		path: '/workbooks-v2',
		name: 'workbooks-v2',
		component: () => import(/* webpackChunkName: "workbooks-v2" */ '@/views/post/WorkbooksV2.vue')
	},

	{
		path: '/essential-guides',
		name: 'essential-guides',
		component: () => import(/* webpackChunkName: "workbooks-v2" */ '@/views/post/EssentialGuides.vue')
	},
	{
		path: '/essential-guides-success',
		name: 'essential-guides-success',
		component: () => import(/* webpackChunkName: "workbooks-v2" */ '@/views/post/EssentialGuidesSuccess.vue')
	},
	{
		path: '/workbooks-bundle',
		name: 'workbooks-bundle',
		component: () => import(/* webpackChunkName: "default-paywall" */ '@/views/post/WorkbooksBundle.vue')
	},

	{
		path: '/workbooks-bundle-v2',
		name: 'workbooks-bundle-v2',
		component: () => import(/* webpackChunkName: "workbooks-bundle-v2" */ '@/views/post/WorkbooksBundleV2.vue')
	},

	{
		path: '/offer-92off-workbooks-bundle',
		name: 'offer-92off-workbooks-bundle',
		component: () => import(/* webpackChunkName: "offer-92-off" */ '@/views/post/Offer92offWorkbooksBundle.vue')
	},

	{
		path: '/success',
		name: 'success',
		component: () => import(/* webpackChunkName: "default-paywall" */ '@/views/post/Success.vue')
	},

	{
		path: '/summer-sale',
		name: 'summer-sale',
		component: () => import(/* webpackChunkName: "summer-sale" */ '@/views/paywall/SummerSaleOffer.vue'),
		props: (route) => ({
			paywallTheme: route.query.paywallTheme
		})
	},

	{
		path: '/after-purchase',
		name: 'after-purchase',
		component: () => import(/* webpackChunkName: "after-purchase" */ '@/views/post/SurveyAfterPurchase.vue')
	},
	{
		path: '/emotional-course',
		name: 'emotional-course',
		component: () => import(/* webpackChunkName: "summer-sale" */ '@/views/post/EmotionalCourse.vue')
	},
	{
		path: '/cancel-sub-offer',
		name: 'cancel-sub-offer',
		component: () => import(/* webpackChunkName: "cancel-sub-offer" */ '@/views/pre/survey/CancelSubOffer.vue')
	},
	{
		path: '/paywall-toggle',
		name: 'paywall-toggle',
		component: () => import(/* webpackChunkName: "paywall-toggle" */ '@/views/paywall/PaywallToggle.vue')
	},
	{
		path: '/slider-paywall',
		name: 'slider-paywall',
		component: () => import(/* webpackChunkName: "slider-paywall" */ '@/views/paywall/SliderPaywall.vue')
	},
	{
		path: '/updated-paywall',
		name: 'updated-paywall',
		component: () => import(/* webpackChunkName: "updated-paywall" */ '@/views/paywall/UpdatedPaywall.vue')
	},
	{
		path: '/ai-powered-paywall',
		name: 'ai-powered-paywall',
		component: () =>
			import(/* webpackChunkName: "ai-powered-paywall" */ '@/views/paywall/AiPoweredLoseWeightPaywall.vue')
	},

	/** Survey */

	{
		path: '/survey',
		component: () => import(/* webpackChunkName: "srv" */ '@/views/pre/survey/Index.vue'),
		children: [
			{
				path: 'rejection-reason',
				name: 'rejection-reason',
				component: () => import(/* webpackChunkName: "rejection-reason" */ '@/views/pre/survey/RejectionReason.vue')
			},
			{
				path: 'grab-a-snack',
				name: 'grab-a-snack',
				component: () => import(/* webpackChunkName: "emotional-course" */ '@/views/survey/GrabASnack.vue')
			},
			{
				path: 'snack-trigger',
				name: 'snack-trigger',
				component: () => import(/* webpackChunkName: "emotional-course" */ '@/views/survey/SnackTrigger.vue')
			},
			{
				path: 'emotional-eating-course-intro',
				name: 'emotional-eating-course-intro',
				component: () =>
					import(/* webpackChunkName: "emotional-course" */ '@/views/survey/EmotionalEatingCourseIntro.vue')
			}
		]
	},
	/** new JF like paywall with screens flow will delete right after new release */
	{
		path: '/jfv2',
		name: 'jfv2',
		component: () => import(/* webpackChunkName: "jf" */ '@/views/paywall/JF/Index.vue'),
		children: [
			{
				path: 'paywall',
				name: 'jfv2-paywall',
				component: () => import(/* webpackChunkName: "jf-paywall" */ '@/views/paywall/JF/Paywall.vue')
			},
			{
				path: 'toggle',
				name: 'jfv2-toggle',
				component: () => import(/* webpackChunkName: "jf-toggle" */ '@/views/paywall/JF/AnimatedToggle.vue')
			},
			{
				path: 'purchase',
				name: 'jfv2-purchase',
				component: () => import(/* webpackChunkName: "jf-purchase" */ '@/views/paywall/JF/DownloadingPlanLoader.vue')
			}
		]
	},
	{
		path: '/jfv3',
		name: 'jfv3',
		component: () => import(/* webpackChunkName: "jf" */ '@/views/paywall/JF/Index.vue'),
		children: [
			{
				path: 'paywall',
				name: 'jfv3-paywall',
				component: () => import(/* webpackChunkName: "jf-paywall" */ '@/views/paywall/JF/Paywall.vue')
			},
			{
				path: 'toggle',
				name: 'jfv3-toggle',
				component: () => import(/* webpackChunkName: "jf-toggle" */ '@/views/paywall/JF/AnimatedToggle.vue')
			},
			{
				path: 'purchase',
				name: 'jfv3-purchase',
				component: () => import(/* webpackChunkName: "jf-purchase" */ '@/views/paywall/JF/DownloadingPlanLoader.vue')
			}
		]
	},
	/** deprecated JF like paywall with screens flow will delete right after new release */
	{
		path: '/jf',
		name: 'jf',
		component: () => import(/* webpackChunkName: "jf" */ '@/views/paywall/JF/Index.vue'),
		children: [
			{
				path: 'paywall',
				name: 'jf-paywall',
				component: () => import(/* webpackChunkName: "jf-paywall" */ '@/views/paywall/JF/Paywall.vue')
			},
			{
				path: 'toggle',
				name: 'jf-toggle',
				component: () => import(/* webpackChunkName: "jf-toggle" */ '@/views/paywall/JF/AnimatedToggle.vue')
			},
			{
				path: 'purchase',
				name: 'jf-purchase',
				component: () => import(/* webpackChunkName: "jf-purchase" */ '@/views/paywall/JF/DownloadingPlanLoader.vue')
			}
		]
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,

	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return {
				top: 0
			}
		}
	}
})

export default router
