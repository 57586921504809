// import products from '@/data/products.json'
// import products from '@/data/products_android.json'
import useApi from '@/composition/api.js'

const products = []
const api = useApi()
const platform = api.getPlatform()

const state = {
	ids: {
		simple_original_subscription_3_months: {
			ios: 'simple_original_subscription_3_months',
			android: 'simple_original_3_month_sale'
		},
		simple_high_price_subscription_3_months: {
			ios: 'simple_high_price_subscription_3_months',
			android: 'android_simple_full_price_subscription_3_months' //TODO
		},
		simple_original_premium_subscription_12_months: {
			ios: 'simple_original_premium_subscription_12_months',
			android: 'simple_premium_year'
		},
		simple_full_price_subscription_3_months: {
			ios: 'simple_full_price_subscription_3_months',
			android: 'android_simple_full_price_subscription_3_months'
		},
		simple_price_down_subscription_3_months: {
			ios: 'simple_price_down_subscription_3_months',
			android: 'simple_original_3_month'
		},
		simple_original_subscription_1_month: {
			ios: 'simple_original_subscription_1_month',
			android: 'simple_original_1_month'
		},
		simple_sale_subscription_12_months_63off_no_trial: {
			ios: 'simple_sale_subscription_12_months_63off_no_trial',
			android: 'simple_sale_year'
		},
		simple_original_subscription_12_months: {
			ios: 'simple_original_subscription_12_months',
			android: 'simple_original_year'
		},
		subscription_12_months_ny_23: {
			ios: 'simple_original_subscription_12_months',
			android: 'simple_discount_year'
		},
		subscription_3_months_ny_23: {
			ios: 'simple_3_months_4999_9_for_introductory',
			android: 'p12w_xmas'
		},
		simple_original_subscription_12_months_trial: {
			ios: 'simple_original_subscription_12_months_trial',
			android: 'android_simple_original_subscription_12_months_trial'
		},
		simple_special_subscription_3_months: {
			ios: 'simple_special_subscription_3_months',
			android: 'android_simple_special_subscription_3_months'
		},
		simple_price_down_subscription_12_months: {
			ios: 'simple_price_down_subscription_12_months',
			android: 'simple_price_down_year'
		},
		simple_high_price_subscription_12_months: {
			ios: 'simple_high_price_subscription_12_months',
			android: 'simple_original_year'
		},
		simple_high_full_price_subscription_12_months: {
			ios: 'simple_high_full_price_subscription_12_months',
			android: 'android_simple_full_price_subscription_3_months'
		},
		simple_high_full_price_subscription_12_months_ss: {
			ios: 'simple_high_full_price_subscription_12_months',
			android: 'simple_discount_year_for_introductory'
		},
		simple_sale_subscription_3_months_for_introductory: {
			ios: 'simple_sale_subscription_3_months_for_introductory',
			android: 'android_simple_full_price_subscription_3_months'
		},
		simple_bf_subscription_3_month_discount: {
			ios: 'simple_price_down_subscription_3_months',
			android: 'simple_original_3_month_sale'
		},
		simple_bf_subscription_3_month_wo_discount: {
			ios: 'simple_original_subscription_3_months',
			android: 'android_simple_full_price_subscription_3_months'
		},
		simple_bf_subscription_annual: {
			ios: 'simple_original_premium_subscription_12_months',
			android: 'simple_original_year'
		},
		simple_xmas_offer_subscription_3_month_discount: {
			ios: 'simple_sale_subscription_3_months_for_introductory',
			android: ''
		},
		simple_sale_subscription_3_months_for_inapppaywall: {
			ios: 'simple_sale_subscription_3_months_for_inapppaywall',
			android: ''
		},
		simple_xmas_offer_subscription_3_month_wo_discount: {
			ios: 'simple_original_subscription_3_months',
			android: ''
		},
		simple_xmas_offer_subscription_annual: {
			ios: 'simple_original_premium_subscription_12_months',
			android: ''
		},
		simple_3_months_4999_9_for_introductory: {
			ios: 'simple_3_months_4999_9_for_introductory',
			android: 'android_simple_full_price_subscription_3_months' /** TODO */
		},
		simple_full_price_subscription_12_months: {
			ios: 'simple_full_price_subscription_12_months',
			android: 'simple_low_price_1_month'
		},
		simple_nutrition_workbook: {
			ios: 'simple_nutrition_workbook',
			android: 'android_simple_nutrition_workbook_low_price'
		},
		simple_workout_workbook: {
			ios: 'simple_workout_workbook',
			android: 'android_simple_workout_workbook_low_price'
		},
		ios_workout_workbook_sale: {
			ios: 'ios_workout_workbook_sale',
			android: ''
		},
		simple_all_workbooks: {
			ios: 'simple_all_workbooks',
			android: 'android_simple_down_all_workbooks'
		},
		simple_down_all_workbooks: {
			ios: 'simple_down_all_workbooks',
			android: 'android_simple_down_all_workbooks'
		},
		simple_original_subscription_12_weeks_2999_trial_7d: {
			ios: 'ios_simple_original_subscription_12_weeks_2999_trial_7d',
			android: 'android_simple_original_subscription_12_weeks_2999_trial_7d'
		},
		simple_original_subscription_12_months_trial_7d: {
			ios: 'simple_original_subscription_12_months_trial_7d',
			android: 'simple_original_year_7_trial'
		},
		simple_special_subscription_12_months: {
			ios: 'simple_special_subscription_12_months',
			android: '' // TODO
		},
		simple_original_subscription_6_months: {
			ios: 'simple_original_subscription_6_months',
			android: 'android_simple_original_subscription_6_months'
		},
		simple_original_lifetime: {
			ios: 'simple_original_lifetime',
			android: '' // TODO
		},
		simple_premium_12_weeks: {
			ios: '', // TODO
			android: 'simple_premium_12_weeks'
		},
		simple_recipes_3_months_7d_trial_low: {
			ios: '',
			android: 'simple_recipes_3_months_7d_trial_low'
		},
		ios_emotional_eating_course: {
			ios: 'ios_emotional_eating_course',
			android: ''
		},
		simple_universal_1_month_7d_trial: {
			ios: '',
			android: 'simple_low_price_1_month'
		},
		simple_universal_1_month: {
			ios: 'ios_simple_premium_1_m_intro',
			android: 'simple_price_down_1_month_2'
		},
		simple_universal_3_month: {
			ios: 'ios_simple_premium_3_month_intro',
			android: 'android_simple_special_subscription_3_months'
		},
		simple_universal_3_month_7_day_trial: {
			ios: 'simple_premium_3_month_7d_trial',
			android: 'simple_premium_12_weeks_7d_trial'
		},
		simple_premium_1m_29_99_1m_17_99: {
			ios: 'ios_simple_premium_1m_29_99_1m_17_99',
			android: 'todo'
		},
		simple_premium_1m_29_99_7d_trial: {
			ios: 'ios_simple_premium_1m_29_99_7d_trial',
			android: 'todo'
		},
		simple_universal_workbooks: {
			ios: 'workbooks_premium_bundle',
			android: 'wb_bundle_new'
		},
		simple_year_sub_high_price: {
			ios: 'simple_high_full_price_subscription_12_months',
			android: 'simple_premium_year'
		},
		simple_premium_trial_1_month: {
			ios: 'simple_premium_2999_7d_free_trial',
			android: 'simple_premium_monthtly_7d_free'
		},
		simple_premium_1_month_intro: {
			ios: 'simple_premium_1_month_intro',
			android: 'simple_price_down_1_month'
		},
		simple_premium_3_month_intro: {
			ios: 'simple_premium_3_month_intro',
			android: 'android_simple_price_down_subscription_3_months'
		},
		simple_premium_6_month_intro: {
			ios: 'simple_premium_6_month_intro',
			android: 'android_simple_original_subscription_6_months'
		},
		simple_wb_onetime_full: {
			ios: 'ios_simple_wb_onetime_full',
			android: 'android_simple_wb_onetime_full'
		},
		simple_sale_subscription_3_months: {
			ios: 'simple_sale_subscription_3_months',
			android: ''
		},
		ios_simple_premium_1m_20_49: {
			ios: 'ios_simple_premium_1m_20_49',
			android: ''
		},
		ios_simple_premium_12m_71_99_7d_trial: {
			ios: 'ios_simple_premium_12m_71_99_7d_trial',
			android: ''
		}
	},
	prices: []
}

const getters = {
	find: (state) => (id) => {
		return state.prices.find((price) => price.id == id)
	},

	getData: (state, getters) => (data) => {
		return data.map(({ ...product }) => {
			const price_data = getters.getProductById(product.id)

			if (price_data) {
				product.id = price_data.id
				product.localized_price = price_data.localizedPriceString
				product.currency_code = price_data.currencyCode
				product.product_price = price_data.price
				product.duration_months = price_data.durationMonths
				// Custom prices fields calculated on the go, consider moving to app
				const price_per_day_introductory = price_data.introductoryPrice / (price_data.durationMonths * 30)
				const price_per_week_introductory = price_data.introductoryPrice / (price_data.durationMonths * 4)
				const price_per_day = price_data.price / (price_data.durationMonths * 30)
				product.price_per_day_introductory = !isNaN(price_per_day_introductory)
					? price_per_day_introductory.toFixed(2)
					: 0
				product.price_per_week_introductory = !isNaN(price_per_week_introductory)
					? price_per_week_introductory.toFixed(2)
					: 0
				product.price_per_day = !isNaN(price_per_day) ? price_per_day.toFixed(2) : 0
				product.custom_currency_symbol =
					price_data.currencySymbol ?? (price_data.currencyCode === 'USD' ? '$' : price_data.currencyCode ?? '')

				if (product.old) {
					const old_product = getters.getProductById(product.old.id)

					product.old = {
						price: old_product.localizedPriceString,
						weekly_price: old_product.localizedPricePerWeekString,
						monthly_price: old_product.localizedPricePerMonthString,
						price_per_year: old_product.localizedPricePerYearString,
						discount: Math.floor(((old_product.price - price_data.price) / old_product.price) * 100),
						discount_per_year: Math.floor(
							(((12 / old_product.durationMonths) * old_product.price -
								(12 / price_data.durationMonths) * price_data.price) /
								((12 / old_product.durationMonths) * old_product.price)) *
								100
						)
					}
				}

				if (price_data.introductoryPrice) {
					product.introductory = {
						discount: Math.floor(((price_data.price - price_data.introductoryPrice) / price_data.price) * 100),
						localized_price: price_data.localizedIntroductoryPrice
					}
				}

				const fields = [
					'terms',
					'text',
					'subtitle',
					'price',
					'text_over_badge',
					'period',
					'sidebar_title',
					'old_price',
					'underTitlePrice',
					'underLinePrice'
				]

				fields.forEach((field) => {
					if (product[field]) {
						product[field] = product[field]
							.replace(/\[price\]/g, price_data.localizedPriceString)
							.replace(/\[introductory_price\]/g, price_data.localizedIntroductoryPrice)
							.replace(/\[weekly_price\]/g, price_data.localizedPricePerWeekString)
							.replace(/\[monthly_price\]/g, price_data.localizedPricePerMonthString)
							.replace(/\[price_per_day_introductory\]/g, product.price_per_day_introductory)
							.replace(/\[price_per_day\]/g, product.price_per_day)
							.replace(/\[yearly_price\]/g, price_data.localizedPricePerYearString)
							.replace(/\[custom_currency_symbol\]/g, product.custom_currency_symbol)
							.replace(/\[weekly_price_introductory\]/g, product.price_per_week_introductory)

						if (typeof product.old?.price !== 'undefined') {
							product[field] = product[field]
								.replace(/\[old_price\]/g, `<s>${product.old.price}</s>`)
								.replace(/\[old_price_per_year\]/g, `<s>${product.old.price_per_year}</s>`)
								.replace(/\[discount_per_year\]/g, `${product.old.discount_per_year}%`)
						}
					}
				})
			}

			return {
				...product,
				hasTrial: price_data?.hasTrial,
				duration_months: price_data?.durationMonths,
				currency: price_data?.currencyCode,
				symbol: price_data?.currencySymbol,
				trial_days: price_data?.trialDurationDays || 0
			}
		})
	},

	getProductById: (state) => (id) => {
		const platform_product_id = state.ids[id][platform]
		return state.prices.find((price) => price.id == platform_product_id)
	}
}

const actions = {}

const mutations = {
	store(state, prices) {
		state.prices = (typeof prices == 'string' ? JSON.parse(prices) : prices) || products
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
